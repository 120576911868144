// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gq0AofT4 .swiper-slide{padding:var(--py) 0}._9vSe3q5M,.BLl\\+VxSF{--top:50%;--dist:-1.5rem;background:transparent;color:inherit;top:50%;top:var(--top);transform:translateY(-50%);transition:visibility 0s,opacity .1s linear;transition-delay:0s,.1s;z-index:1}@media(max-width:1365.98px){._9vSe3q5M,.BLl\\+VxSF{--dist:-0.94rem}}._9vSe3q5M>:first-child,.BLl\\+VxSF>:first-child{padding:.75rem}@media(max-width:1365.98px){._9vSe3q5M>:first-child,.BLl\\+VxSF>:first-child{padding:.38rem}}@media(max-width:767.98px){._9vSe3q5M>:first-child,.BLl\\+VxSF>:first-child{padding:.25rem}}._9vSe3q5M svg,.BLl\\+VxSF svg{height:1.44rem;width:1.44rem}._9vSe3q5M.vfvUn21B,.BLl\\+VxSF.vfvUn21B{opacity:0;transition-delay:.1s,0s;visibility:hidden}.BLl\\+VxSF{left:var(--dist)}._9vSe3q5M{right:var(--dist)}._9vSe3q5M svg{transform:scaleX(-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": "Gq0AofT4",
	"nextEl": "_9vSe3q5M",
	"prevEl": "BLl+VxSF",
	"hidden": "vfvUn21B"
};
module.exports = ___CSS_LOADER_EXPORT___;
