const propsKey = "layoutProps"

export default {
  computed: {
    [propsKey] () {
      const component = this.$route.matched[0]?.components.default
      if (!component) return {}
      return component.options?.[propsKey] || component[propsKey] || {}
    },
  },
}
