export const state = () => ({
  // См. @/plugins/init.client
  historyLen: 0,
  sale: false,
})

export const getters = {
  historyLen: state => state.historyLen ?? 0,
}

export const mutations = {
  SET_HISTORY_LEN: (state, historyLen) => { state.historyLen = historyLen },
  SET_SALE: (state, sale) => { state.sale = sale },
}
