export const state = () => ({
  tags: [],
})

export const getters = {
  tags: state => state.tags || [],

  _getTagsByGroup: (_, getters) => groupAlias =>
    getters.tags.filter(t => t.group.alias === groupAlias),
  interests: (_, getters) => getters._getTagsByGroup("interests"),
  industries: (_, getters) => getters._getTagsByGroup("industry"),
  activitySpheres: (_, getters) => getters._getTagsByGroup("activity_sphere"),

  getOptions: (_state, getters) =>
    (i18n, localize, { key, withOther = true }) => {
      const entries = getters[key].map(s => [s.id, localize(s.title)])
      if (withOther) entries.push(["other", i18n.t("input.other")])
      return Object.fromEntries(entries)
    },
}

export const mutations = {
  SET_TAGS (state, tags) { state.tags = tags },
}

export const actions = {
  async fetchTags ({ commit }) {
    const [res, err] = await this.$api.tag.getTags()
    if (err) {
      console.error(err)
      return
    }
    const tags = this.$api.helpers.getList(res)
    commit("SET_TAGS", tags)
  },
}
