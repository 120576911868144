
const variants = [
  "#54b774",
  "#52b9c6",
  "#d91413",
  "#8a4d8d",
  "#ff8a00",
]

export default {
  props: {
    user: { type: Object, required: true },
    scale: { type: Number, default: 1 },
  },

  computed: {
    initials () {
      return ["firstName", "lastName"]
        .map(k => this.$fmt.localize(this.user[k]).charAt(0)).join("")
    },

    variant () {
      const sum = this.user.id.split("")
        .map(c => c.charCodeAt(0))
        .reduce((c, acc) => acc + c, 0)
      return variants[sum % variants.length]
    },
  },
}
