
import { mapGetters, mapActions } from "vuex"

export default {
  computed: {
    ...mapGetters("user", [
      "user", "isAdmin", "isImpersonalized", "isEditAllowed",
    ]),

    isRatingVisible () {
      return false
      // return !["mentor", "mentor-speaker", "speaker"]
      //   .includes(this.user.status.alias)
    },

    fullName () {
      return this.$fmt.name(this.user)
    },
  },

  methods: {
    ...mapActions("user", ["logout", "exitImpersonalization"]),
  },
}
