// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RZMbhySL{align-items:center;background:#b1ec52;border-radius:.6em;color:#000;display:flex;font-weight:600;justify-content:center;line-height:1.4;padding:.8em 1.8em;position:relative;transition:background .2s ease-in-out;white-space:nowrap}.RZMbhySL:disabled{cursor:not-allowed;filter:grayscale(100%)}@media(hover:hover){.RZMbhySL:not(:disabled):hover{background:#9ce724;color:#000}}@media(hover:none){.RZMbhySL:not(:disabled):active{background:#9ce724;color:#000}}.RZMbhySL:not(:disabled):active{background:#80c315}._07pUbZMk{background:#1a153f;color:#b1ec52}@media(hover:hover){._07pUbZMk:not(:disabled):hover{background:#120f2c;color:#b1ec52}}@media(hover:none){._07pUbZMk:not(:disabled):active{background:#120f2c;color:#b1ec52}}._07pUbZMk:not(:disabled):active{background:#0a0819}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "RZMbhySL",
	"btnSecondary": "_07pUbZMk"
};
module.exports = ___CSS_LOADER_EXPORT___;
