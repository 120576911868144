import { nanoid } from "nanoid"

export interface Toast {
  id: string
  title: string
  body?: string
  icon?: string
  onClick?: Function
  autoHide?: boolean
}

function showToast (toast: Omit<Toast, "id">) {
  if (!toast) return
  window.$nuxt.$emit("toast", { ...toast, id: nanoid() })
}

const plugin: NuxtPlugin = (_ctx, inject) => {
  inject("toast", showToast)
}
export default plugin

type ShowToast = typeof showToast
declare module "vue/types/vue" {
  interface Vue { $toast: ShowToast }
}
declare module "@nuxt/types" {
  interface NuxtAppOptions { $toast: ShowToast }
  interface Context { $toast: ShowToast }
}
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> { $toast: ShowToast }
}
