
import { getOtherLocale } from "@/utils/i18n"

export default {
  props: {
    isDark: { type: Boolean, default: false },
  },

  computed: {
    otherLocale () {
      return getOtherLocale(this.$i18n)
    },
  },
}
