import { wrap } from "../_"

type UpdateUserData = any // TODO
type CreateUserData = any // TODO
type CreateUserQuickData = any // TODO

interface SendTokenData { email: string, status?: string }

const pfx = "/user"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/favorite`,
      { ...cfg })),

  addFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${userId}/favorite`, {},
      { ...cfg })),

  removeFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/${userId}/favorite`,
      { ...cfg })),

  getUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  updateUser: (data: UpdateUserData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}`, data,
      { ...cfg })),

  getUser: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),

  getCountries: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/countries`,
      { cache: true, ...cfg })),

  getColleague: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/colleague/${userId}`,
      { ...cfg })),

  updateColleague: (userId: string, data: UpdateUserData, cfg?: AxiosConfig) =>
    wrap(
      a.$patch(`${pfx}/colleague/${userId}`, data,
        { ...cfg })),

  createColleague: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/colleague`, data,
      { ...cfg })),

  createUser: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),

  sendToken: (data: SendTokenData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/registration-token`, data,
      { ...cfg })),

  getTokenData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/registration-token/${token}`,
      { ...cfg })),

  createUserQuick: (data: CreateUserQuickData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/quick-registration`, data,
      { ...cfg })),

  getPreviousData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/previous-user/${token}`,
      { ...cfg })),

  getA5KUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/a5k`,
      { cache: true, ...cfg })),

  getRecommendedUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/recommended`,
      { ...cfg })),
})
