
import breakpoints from "@/assets/style/resources/breakpoints/module.scss"

export default {
  data: () => ({
    users: [],
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 40,
      breakpoints: {
        [breakpoints.sm]: { slidesPerView: 2 },
        [breakpoints.lg]: { slidesPerView: 3 },
        [breakpoints.xl]: { slidesPerView: 4 },
      },
    },
  }),

  async mounted () {
    await this.fetchUsers()
  },

  methods: {
    async fetchUsers () {
      const [res, err] = await this.$api.user.getRecommendedUsers()
      if (err) {
        console.error(err)
        return
      }
      this.users = res || []
    },
  },
}
