// Методы форматирования чаще всего зависимы от локали приложения,
// поэтому они инъектированы глобально, без явного её указания.
// Vue 2 фильтры не используются, т.к они устарели во Vue 3.
// Так процесс миграции в будущем пройдет проще

import { getOtherLocale } from "@/utils/i18n"

function getLocalize (i18n: any) {
  return function (item: any) {
    const get = (k: string) => {
      if (!item) return ""
      if (typeof item === "string") return item
      const v = item[k] || ""
      return typeof v === "string" ? v.trim() : v
    }
    return get(i18n.locale) || get(getOtherLocale(i18n)) || ""
  }
}

function getFormatDate ($moment: any, i18n: any) {
  return function (date: Date | number, pattern: string) {
    return $moment(date).locale(i18n.locale).format(pattern)
  }
}

const getFormatters = ({ app: { i18n, $moment } }: NuxtContext) => {
  const localize = getLocalize(i18n)
  const formatDate = getFormatDate($moment, i18n)

  return {
    localize,

    // Даты
    // https://momentjs.com/docs/#/displaying/format
    date: formatDate,
    time: (date: Date | number) => formatDate(date, "HH:mm"),

    // Полное имя пользователя
    name: (user: any) => (["firstName", "lastName"] as const)
      .map(k => localize(user[k])).filter(Boolean).join(" "),

    // Компания пользователя
    company: (user: any) => user.attributes?.companyForBadge ||
      localize(user.company),
  }
}

const plugin: NuxtPlugin = (ctx, inject) => {
  inject("fmt", getFormatters(ctx))
}
export default plugin

type Formatters = ReturnType<typeof getFormatters>
declare module "vue/types/vue" {
  interface Vue { $fmt: Formatters }
}
declare module "@nuxt/types" {
  interface NuxtAppOptions { $fmt: Formatters }
  interface Context { $fmt: Formatters }
}
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> { $fmt: Formatters }
}
