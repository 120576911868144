
import { mapGetters, mapActions } from "vuex"
// import { storageKeys, getLs, setLs } from "@/utils/storage"

export default {
  middleware: "auth",

  data: () => ({
    isSessionsVisible: false,
    // isPassportModalOpen: false,
  }),

  async fetch () {
    await this.fetchExhibition()
  },

  computed: {
    ...mapGetters("user", ["isEditAllowed"]),
  },

  // watch: {
  //   "$route" () { this.isPassportModalOpen = false },
  // },

  // mounted () {
  //   this.openPassportModal()
  // },

  methods: {
    ...mapActions("user", ["fetchExhibition"]),

    scrollToRec () {
      this.$refs.recUsers?.$el?.scrollIntoView({ behavior: "smooth" })
    },

    // openPassportModal () {
    //   if (!this.isEditAllowed) return
    //   const key = storageKeys.passportModalWasOpen
    //   const wasOpen = getLs(key)
    //   if (wasOpen) return
    //   this.isPassportModalOpen = true
    //   setLs(key, true)
    // },
  },
}
