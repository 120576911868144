import { wrap } from "../_"

const pfx = "/faq"

export default (a: NuxtAxiosInstance) => ({
  getFaq: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })
      .then(r => r?.list || [])),
})
