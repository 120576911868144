export const state = () => ({
  tracks: [],
})

export const getters = {
  tracks: state => state.tracks || [],
  mainTrack: (_state, getters) => getters.tracks[0],
}

export const mutations = {
  SET_TRACKS (state, tracks) { state.tracks = tracks },
}

export const actions = {
  async fetchTracks ({ commit, getters }) {
    if (getters.tracks.length) return
    const [res, err] = await this.$api.program.getTracks()
    if (err) {
      console.error(err)
      return
    }
    const tracks = this.$api.helpers.getList(res)
    commit("SET_TRACKS", tracks)
  },
}
