import { render, staticRenderFns } from "./account.vue?vue&type=template&id=58eff7d4"
import script from "./account.vue?vue&type=script&lang=js"
export * from "./account.vue?vue&type=script&lang=js"
import style0 from "./account.vue?vue&type=style&index=0&id=58eff7d4&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountHeader: require('/app/components/account/AccountHeader.vue').default,AppContent: require('/app/components/app/AppContent.vue').default,AccountTabs: require('/app/components/account/AccountTabs.vue').default,AccountRecUsers: require('/app/components/account/AccountRecUsers.vue').default,AppLayout: require('/app/components/app/AppLayout.vue').default})
