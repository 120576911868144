import { wrap } from "../_"

const pfx = "/event"

export default (a: NuxtAxiosInstance) => ({
  getStatuses: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/status`,
      { cache: true, ...cfg })),

  getEventData: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),
})
