
export default {
  props: {
    isLight: { type: Boolean, default: true },
    isQuick: { type: Boolean, default: false },
  },

  computed: {
    noBanner () {
      const routeName = this.$route.name
      if (!routeName) return true
      return [
        "registration",
        "login",
        "account",
      ].some(n => routeName.includes(n))
    },
  },
}
