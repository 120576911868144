export default {
  props: {
    as: { type: String, default: "" },
    to: { type: [String, Object], default: "" },
    isLoading: { type: Boolean, default: false },
    notReactive: { type: Boolean, default: false },
  },

  computed: {
    is () {
      const { as } = this
      if (as) return as
      if (this.to) return "nuxt-link"
      return "button"
    },
    props () {
      const { is, to } = this
      if (is === "button") return { type: this.$attrs.type || "button" }
      if (is === "a") return { href: to }
      if (to) return { to }
      return undefined
    },
  },
}
