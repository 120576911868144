// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h12OIGTO{background:linear-gradient(180deg,#1a153f,#010009)}.x8I1ckRJ{background:#fff}.-BEHtq5A{background:linear-gradient(180deg,#b1ec52 0,rgba(177,236,82,0) 98.97%);height:62.5rem;max-height:100vh;pointer-events:none}.Vo1wGoMN{--header-btn-bg-hover:hsla(0,0%,100%,.1);--header-btn-bg-active:rgba(0,0,0,.1);--btn-primary-text-color:#fff;--btn-primary-bg:#b1ec52;--btn-primary-bg-hover:#bbef69;--btn-primary-bg-active:#a7e93b;--btn-primary-border-color:#fff;--btn-secondary-text-color:#fff;--btn-secondary-bg:#000;--btn-secondary-bg-hover:#0d0d0d;--btn-secondary-bg-active:#000;--btn-secondary-border-color:#fff;color:#fff}.ZgHNfdov{--btn-primary-text-color:#b1ec52;--btn-primary-bg:#fff;--btn-primary-bg-hover:#f9f9f9;--btn-primary-bg-active:#f2f2f2;--btn-primary-border-color:#b1ec52;--btn-secondary-text-color:#1a153f;--btn-secondary-bg:#fff;--btn-secondary-bg-hover:#f9f9f9;--btn-secondary-bg-active:#f2f2f2;--btn-secondary-border-color:#1a153f;color:#1a153f}.V2I-u8Cp{margin-bottom:max(2.11rem,min(3.13vw,3.75rem))}.B\\+dfvtfy{margin-bottom:max(1.83rem,min(2.71vw,3.25rem));padding:max(1.48rem,min(2.19vw,2.63rem)) 0 0}.S-NigYlp{font-size:2.69rem;margin-bottom:.5em;margin-top:2.23em;width:-webkit-max-content;width:-moz-max-content;width:max-content}@media(max-width:1365.98px){.S-NigYlp{font-size:2rem}}@media(max-width:767.98px){.S-NigYlp{font-size:1.38rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": "h12OIGTO",
	"bgLight": "x8I1ckRJ",
	"gradient": "-BEHtq5A",
	"container": "Vo1wGoMN",
	"containerLight": "ZgHNfdov",
	"content": "V2I-u8Cp",
	"header": "B+dfvtfy",
	"btn": "S-NigYlp"
};
module.exports = ___CSS_LOADER_EXPORT___;
