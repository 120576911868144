
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState("user", ["exhibition"]),
    ...mapGetters("user", [
      "user",
      "isPaymentAllowed",
      "isAdmin",
      "isPaid",
      "isApproved",
      "isOffline",
      "isCarPassAllowed",
      "isExhibitionAllowed",
      "isContestVisible",
      "isBadgeVisible",
    ]),
    ...mapGetters("chat", ["unreadCount"]),

    isScheduleVisible () {
      return true
    },

    isPaymentVisible () {
      return this.$config.features.payment && this.isPaymentAllowed
    },

    isExhibitionVisible () {
      return this.exhibition?.type === "offline" ||
        Boolean(this.user.attributes?.wantToExhibit)
    },

    isChatsVisible () {
      return true
    },

    isCarPassVisible () {
      return this.isCarPassAllowed
    },

    tabs () {
      const t = token => this.$t(`account.tabs.${token}`)

      const tabs = [{ title: t("account"), to: "/account", exact: true }]

      if (this.isExhibitionVisible) {
        tabs.push({
          title: t("exhibition"), to: "/account/exhibition", exact: true,
        })
      }

      // if (this.isContestVisible) {
      //   tabs.push({
      //     title: t("contest"), to: "/account/contest", exact: true,
      //   })
      // }

      if (this.isPaymentVisible)
        tabs.push({ title: t("payment"), to: "/account/payment" })

      if (this.isScheduleVisible) {
        tabs.push({
          title: t("schedule"), to: "/account/schedule", exact: true,
        })
      }

      if (this.isChatsVisible) {
        tabs.push({
          title: t("chats"),
          to: "/account/chat",
          attention: Boolean(this.unreadCount),
        })
      }

      if (this.isCarPassVisible)
        tabs.push({ title: t("carPass"), to: "/account/car-pass", exact: true })

      if (this.isBadgeVisible) {
        tabs.push({
          title: t("badge"),
          isRed: true,
          newTab: true,
          to: "/badge",
        })
      }

      return tabs
    },
  },
}
