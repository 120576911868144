import { client, isStickerMessage, cleanReply } from "@/utils/chat"

let chat

const getStop = ({ store }) => () => {
  chat?.close()
  store.commit("chat/RESET")
}

const getStart = ({ $auth, store, $fmt, $i18n, $toast, $config, app }) => () => {
  if (!process.browser) return // для подстраховки, можно наверное убрать

  if (!$auth.loggedIn) return

  chat = client("private_chat",
    $auth.strategy.token.get().replace("Bearer ", ""))

  chat.addOnMessageListener("connect", () => { chat.send("list_chats") })

  chat.addOnMessageListener("list_chats", (message) => {
    const messagesCount = message.data.reduce(
      (acc, chat) => {
        const isBad = chat.type === "channel" || !chat.otherParticipant
        return acc + (isBad ? 0 : chat.unreadCount)
      },
      0,
    )
    store.commit("chat/SET_UNREAD_COUNT", messagesCount)
  })

  chat.addOnMessageListener("get_message", (message) => {
    const { data } = message
    if (data.chat === store.getters["chat/currentChat"]) return
    store.commit("chat/INCREMENT_UNREAD_COUNT")

    const { author } = data
    const title = $fmt.name(author)
    const body = isStickerMessage(data)
      ? `*${$i18n.t("chat.sticker")}*`
      : cleanReply(data.text, { strip: true })

    $toast({
      title,
      body,
      onClick () {
        app.router.push(app.localePath(`/account/chat?user=${author.id}`))
      },
      icon: author.photo?.url || "",
    })
  })

  chat.connect($config.chatUrl)
}

export default (ctx, inject) => {
  const stop = getStop(ctx)
  const start = getStart(ctx)
  inject("notifications", {
    restart () {
      stop()
      start()
    },
  })
}
