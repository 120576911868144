export default ({ app: { router } }) => {
    let ready = false

    router.onReady(() => {
        // Mark when the router has completed the initial navigation.
        ready = true
    })

    function create() {
        var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({id: "3247588", type: "pageView", start: (new Date()).getTime(), pid: "USER_ID"});

        router.afterEach((to, from) => {
            if (!ready) {
                // Don't record a duplicate hit for the initial navigation.
                return
            }
            // _tmr.hit(to.fullPath, {
            //   referer: from.fullPath
            // TODO: pass title: <new page title>
            // This will need special handling because router.afterEach is called *before* DOM is updated.
            // })
        })
    }

    if (window._tmr) {
        // Yandex.Metrika API is already available.
        create()
    } else {
        // Yandex.Metrika has not loaded yet, register a callback.
        (function (w, c) {
            (w[c] = w[c] || []).push(create)
        })(window, 'callbacks')
    }
}
