// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iqCtPOgg .hero-container{margin-top:-2rem}.iqCtPOgg .hero-heading{font-family:Unbounded,sans-serif;font-weight:700;line-height:1.55;margin-bottom:.64em;text-transform:uppercase}.iqCtPOgg .hero-theme{font-family:Unbounded,sans-serif;font-size:.75rem;font-weight:700;line-height:1.39;margin-bottom:1.3em;max-width:26.88rem;text-transform:uppercase}@media(max-width:991.98px){.iqCtPOgg .hero-theme{max-width:25rem}}@media(max-width:991.98px){.iqCtPOgg .hero-theme{font-size:.63rem;max-width:18.75rem}}.iqCtPOgg .hero-date{color:#fff;font-family:Unbounded,sans-serif;font-size:2.25rem;font-weight:700;line-height:1.41;margin-bottom:.5em;text-transform:uppercase;white-space:nowrap}@media(max-width:575.98px){.iqCtPOgg .hero-date{font-size:1.5rem}}.iqCtPOgg .hero-where{font-weight:700;line-height:1.14;margin:0;text-transform:uppercase}@media(max-width:767.98px){.iqCtPOgg .hero-where{font-size:.75rem}}.iqCtPOgg .hero-soon{color:#fff;font-family:Unbounded,sans-serif;font-size:.75rem;font-weight:700;line-height:1.41;margin-top:2em;text-transform:uppercase;white-space:nowrap}@media(max-width:575.98px){.iqCtPOgg .hero-soon{font-size:.63rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "iqCtPOgg"
};
module.exports = ___CSS_LOADER_EXPORT___;
