import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _47ea8eae = () => interopDefault(import('../pages/badge.vue' /* webpackChunkName: "pages/badge" */))
const _a0baec80 = () => interopDefault(import('../pages/become-a-partner.vue' /* webpackChunkName: "pages/become-a-partner" */))
const _12bb0ebd = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _7bc5ee1a = () => interopDefault(import('../pages/contest.vue' /* webpackChunkName: "pages/contest" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67c0c664 = () => interopDefault(import('../pages/exhibitors/index.vue' /* webpackChunkName: "pages/exhibitors/index" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _44a74c28 = () => interopDefault(import('../pages/highlights/index.vue' /* webpackChunkName: "pages/highlights/index" */))
const _493d77ea = () => interopDefault(import('../pages/how-to-get.vue' /* webpackChunkName: "pages/how-to-get" */))
const _2600dc7b = () => interopDefault(import('../pages/hub.vue' /* webpackChunkName: "pages/hub" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _f2443126 = () => interopDefault(import('../pages/mentor-hours.vue' /* webpackChunkName: "pages/mentor-hours" */))
const _60d4505e = () => interopDefault(import('../pages/networking.vue' /* webpackChunkName: "pages/networking" */))
const _ede5e6a6 = () => interopDefault(import('../pages/old.vue' /* webpackChunkName: "pages/old" */))
const _2a343e8a = () => interopDefault(import('../pages/participants.vue' /* webpackChunkName: "pages/participants" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _2b917bb8 = () => interopDefault(import('../pages/photo.vue' /* webpackChunkName: "pages/photo" */))
const _6c11bbf4 = () => interopDefault(import('../pages/pitches.vue' /* webpackChunkName: "pages/pitches" */))
const _446d0768 = () => interopDefault(import('../pages/press-center/index.vue' /* webpackChunkName: "pages/press-center/index" */))
const _1a6d1a92 = () => interopDefault(import('../pages/price/index.vue' /* webpackChunkName: "pages/price/index" */))
const _7120d68d = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _41d65b66 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _3429c641 = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _a4dff4fe = () => interopDefault(import('../pages/video.vue' /* webpackChunkName: "pages/video" */))
const _621a7bf6 = () => interopDefault(import('../pages/account/car-pass.vue' /* webpackChunkName: "pages/account/car-pass" */))
const _4af7ab38 = () => interopDefault(import('../pages/account/contest.vue' /* webpackChunkName: "pages/account/contest" */))
const _4ce12cd4 = () => interopDefault(import('../pages/account/edit.vue' /* webpackChunkName: "pages/account/edit" */))
const _1af25fdb = () => interopDefault(import('../pages/account/exhibition.vue' /* webpackChunkName: "pages/account/exhibition" */))
const _31946424 = () => interopDefault(import('../pages/account/exhibition-plate.vue' /* webpackChunkName: "pages/account/exhibition-plate" */))
const _9e0043a6 = () => interopDefault(import('../pages/account/payment/index.vue' /* webpackChunkName: "pages/account/payment/index" */))
const _21cf65a3 = () => interopDefault(import('../pages/account/schedule.vue' /* webpackChunkName: "pages/account/schedule" */))
const _5843ea10 = () => interopDefault(import('../pages/login/change-password.vue' /* webpackChunkName: "pages/login/change-password" */))
const _6524bf60 = () => interopDefault(import('../pages/login/impersonalize.vue' /* webpackChunkName: "pages/login/impersonalize" */))
const _59fd2a44 = () => interopDefault(import('../pages/login/recover.vue' /* webpackChunkName: "pages/login/recover" */))
const _1f49eda0 = () => interopDefault(import('../pages/registration/email.vue' /* webpackChunkName: "pages/registration/email" */))
const _9cf9cdec = () => interopDefault(import('../pages/account/payment/colleague.vue' /* webpackChunkName: "pages/account/payment/colleague" */))
const _7e98f38f = () => interopDefault(import('../pages/account/chat/_id.vue' /* webpackChunkName: "pages/account/chat/_id" */))
const _7c265882 = () => interopDefault(import('../pages/program/session/_alias.vue' /* webpackChunkName: "pages/program/session/_alias" */))
const _2f0d4baa = () => interopDefault(import('../pages/episode/_sessionAlias.vue' /* webpackChunkName: "pages/episode/_sessionAlias" */))
const _18d511cc = () => interopDefault(import('../pages/exhibitors/_id.vue' /* webpackChunkName: "pages/exhibitors/_id" */))
const _96d557b2 = () => interopDefault(import('../pages/highlights/_alias.vue' /* webpackChunkName: "pages/highlights/_alias" */))
const _244009bb = () => interopDefault(import('../pages/partners/_alias.vue' /* webpackChunkName: "pages/partners/_alias" */))
const _8fc70472 = () => interopDefault(import('../pages/press-center/_alias.vue' /* webpackChunkName: "pages/press-center/_alias" */))
const _2dc57272 = () => interopDefault(import('../pages/speakers/_alias.vue' /* webpackChunkName: "pages/speakers/_alias" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d8a8f054,
    name: "about___ru___default"
  }, {
    path: "/account",
    component: _cbd4fa14,
    name: "account___ru___default"
  }, {
    path: "/badge",
    component: _47ea8eae,
    name: "badge___ru___default"
  }, {
    path: "/become-a-partner",
    component: _a0baec80,
    name: "become-a-partner___ru___default"
  }, {
    path: "/contacts",
    component: _12bb0ebd,
    name: "contacts___ru___default"
  }, {
    path: "/contest",
    component: _7bc5ee1a,
    name: "contest___ru___default"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/exhibitors",
    component: _67c0c664,
    name: "exhibitors___ru___default"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq___ru___default"
  }, {
    path: "/highlights",
    component: _44a74c28,
    name: "highlights___ru___default"
  }, {
    path: "/how-to-get",
    component: _493d77ea,
    name: "how-to-get___ru___default"
  }, {
    path: "/hub",
    component: _2600dc7b,
    name: "hub___ru___default"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login___ru___default"
  }, {
    path: "/mentor-hours",
    component: _f2443126,
    name: "mentor-hours___ru___default"
  }, {
    path: "/networking",
    component: _60d4505e,
    name: "networking___ru___default"
  }, {
    path: "/old",
    component: _ede5e6a6,
    name: "old___ru___default"
  }, {
    path: "/participants",
    component: _2a343e8a,
    name: "participants___ru___default"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners___ru___default"
  }, {
    path: "/photo",
    component: _2b917bb8,
    name: "photo___ru___default"
  }, {
    path: "/pitches",
    component: _6c11bbf4,
    name: "pitches___ru___default"
  }, {
    path: "/press-center",
    component: _446d0768,
    name: "press-center___ru___default"
  }, {
    path: "/price",
    component: _1a6d1a92,
    name: "price___ru___default"
  }, {
    path: "/program",
    component: _7120d68d,
    name: "program___ru___default"
  }, {
    path: "/registration",
    component: _41d65b66,
    name: "registration___ru___default"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/speakers",
    component: _3429c641,
    name: "speakers___ru___default"
  }, {
    path: "/video",
    component: _a4dff4fe,
    name: "video___ru___default"
  }, {
    path: "/account/car-pass",
    component: _621a7bf6,
    name: "account-car-pass___ru___default"
  }, {
    path: "/account/contest",
    component: _4af7ab38,
    name: "account-contest___ru___default"
  }, {
    path: "/account/edit",
    component: _4ce12cd4,
    name: "account-edit___ru___default"
  }, {
    path: "/account/exhibition",
    component: _1af25fdb,
    name: "account-exhibition___ru___default"
  }, {
    path: "/account/exhibition-plate",
    component: _31946424,
    name: "account-exhibition-plate___ru___default"
  }, {
    path: "/account/payment",
    component: _9e0043a6,
    name: "account-payment___ru___default"
  }, {
    path: "/account/schedule",
    component: _21cf65a3,
    name: "account-schedule___ru___default"
  }, {
    path: "/en/about",
    component: _d8a8f054,
    name: "about___en"
  }, {
    path: "/en/account",
    component: _cbd4fa14,
    name: "account___en"
  }, {
    path: "/en/badge",
    component: _47ea8eae,
    name: "badge___en"
  }, {
    path: "/en/become-a-partner",
    component: _a0baec80,
    name: "become-a-partner___en"
  }, {
    path: "/en/contacts",
    component: _12bb0ebd,
    name: "contacts___en"
  }, {
    path: "/en/contest",
    component: _7bc5ee1a,
    name: "contest___en"
  }, {
    path: "/en/exhibitors",
    component: _67c0c664,
    name: "exhibitors___en"
  }, {
    path: "/en/faq",
    component: _ca324bc8,
    name: "faq___en"
  }, {
    path: "/en/highlights",
    component: _44a74c28,
    name: "highlights___en"
  }, {
    path: "/en/how-to-get",
    component: _493d77ea,
    name: "how-to-get___en"
  }, {
    path: "/en/hub",
    component: _2600dc7b,
    name: "hub___en"
  }, {
    path: "/en/login",
    component: _cad79e9c,
    name: "login___en"
  }, {
    path: "/en/mentor-hours",
    component: _f2443126,
    name: "mentor-hours___en"
  }, {
    path: "/en/networking",
    component: _60d4505e,
    name: "networking___en"
  }, {
    path: "/en/old",
    component: _ede5e6a6,
    name: "old___en"
  }, {
    path: "/en/participants",
    component: _2a343e8a,
    name: "participants___en"
  }, {
    path: "/en/partners",
    component: _1c6abc50,
    name: "partners___en"
  }, {
    path: "/en/photo",
    component: _2b917bb8,
    name: "photo___en"
  }, {
    path: "/en/pitches",
    component: _6c11bbf4,
    name: "pitches___en"
  }, {
    path: "/en/press-center",
    component: _446d0768,
    name: "press-center___en"
  }, {
    path: "/en/price",
    component: _1a6d1a92,
    name: "price___en"
  }, {
    path: "/en/program",
    component: _7120d68d,
    name: "program___en"
  }, {
    path: "/en/registration",
    component: _41d65b66,
    name: "registration___en"
  }, {
    path: "/en/speakers",
    component: _3429c641,
    name: "speakers___en"
  }, {
    path: "/en/video",
    component: _a4dff4fe,
    name: "video___en"
  }, {
    path: "/login/change-password",
    component: _5843ea10,
    name: "login-change-password___ru___default"
  }, {
    path: "/login/impersonalize",
    component: _6524bf60,
    name: "login-impersonalize___ru___default"
  }, {
    path: "/login/recover",
    component: _59fd2a44,
    name: "login-recover___ru___default"
  }, {
    path: "/registration/email",
    component: _1f49eda0,
    name: "registration-email___ru___default"
  }, {
    path: "/ru/about",
    component: _d8a8f054,
    name: "about___ru"
  }, {
    path: "/ru/account",
    component: _cbd4fa14,
    name: "account___ru"
  }, {
    path: "/ru/badge",
    component: _47ea8eae,
    name: "badge___ru"
  }, {
    path: "/ru/become-a-partner",
    component: _a0baec80,
    name: "become-a-partner___ru"
  }, {
    path: "/ru/contacts",
    component: _12bb0ebd,
    name: "contacts___ru"
  }, {
    path: "/ru/contest",
    component: _7bc5ee1a,
    name: "contest___ru"
  }, {
    path: "/ru/exhibitors",
    component: _67c0c664,
    name: "exhibitors___ru"
  }, {
    path: "/ru/faq",
    component: _ca324bc8,
    name: "faq___ru"
  }, {
    path: "/ru/highlights",
    component: _44a74c28,
    name: "highlights___ru"
  }, {
    path: "/ru/how-to-get",
    component: _493d77ea,
    name: "how-to-get___ru"
  }, {
    path: "/ru/hub",
    component: _2600dc7b,
    name: "hub___ru"
  }, {
    path: "/ru/login",
    component: _cad79e9c,
    name: "login___ru"
  }, {
    path: "/ru/mentor-hours",
    component: _f2443126,
    name: "mentor-hours___ru"
  }, {
    path: "/ru/networking",
    component: _60d4505e,
    name: "networking___ru"
  }, {
    path: "/ru/old",
    component: _ede5e6a6,
    name: "old___ru"
  }, {
    path: "/ru/participants",
    component: _2a343e8a,
    name: "participants___ru"
  }, {
    path: "/ru/partners",
    component: _1c6abc50,
    name: "partners___ru"
  }, {
    path: "/ru/photo",
    component: _2b917bb8,
    name: "photo___ru"
  }, {
    path: "/ru/pitches",
    component: _6c11bbf4,
    name: "pitches___ru"
  }, {
    path: "/ru/press-center",
    component: _446d0768,
    name: "press-center___ru"
  }, {
    path: "/ru/price",
    component: _1a6d1a92,
    name: "price___ru"
  }, {
    path: "/ru/program",
    component: _7120d68d,
    name: "program___ru"
  }, {
    path: "/ru/registration",
    component: _41d65b66,
    name: "registration___ru"
  }, {
    path: "/ru/speakers",
    component: _3429c641,
    name: "speakers___ru"
  }, {
    path: "/ru/video",
    component: _a4dff4fe,
    name: "video___ru"
  }, {
    path: "/account/payment/colleague",
    component: _9cf9cdec,
    name: "account-payment-colleague___ru___default"
  }, {
    path: "/en/account/car-pass",
    component: _621a7bf6,
    name: "account-car-pass___en"
  }, {
    path: "/en/account/contest",
    component: _4af7ab38,
    name: "account-contest___en"
  }, {
    path: "/en/account/edit",
    component: _4ce12cd4,
    name: "account-edit___en"
  }, {
    path: "/en/account/exhibition",
    component: _1af25fdb,
    name: "account-exhibition___en"
  }, {
    path: "/en/account/exhibition-plate",
    component: _31946424,
    name: "account-exhibition-plate___en"
  }, {
    path: "/en/account/payment",
    component: _9e0043a6,
    name: "account-payment___en"
  }, {
    path: "/en/account/schedule",
    component: _21cf65a3,
    name: "account-schedule___en"
  }, {
    path: "/en/login/change-password",
    component: _5843ea10,
    name: "login-change-password___en"
  }, {
    path: "/en/login/impersonalize",
    component: _6524bf60,
    name: "login-impersonalize___en"
  }, {
    path: "/en/login/recover",
    component: _59fd2a44,
    name: "login-recover___en"
  }, {
    path: "/en/registration/email",
    component: _1f49eda0,
    name: "registration-email___en"
  }, {
    path: "/ru/account/car-pass",
    component: _621a7bf6,
    name: "account-car-pass___ru"
  }, {
    path: "/ru/account/contest",
    component: _4af7ab38,
    name: "account-contest___ru"
  }, {
    path: "/ru/account/edit",
    component: _4ce12cd4,
    name: "account-edit___ru"
  }, {
    path: "/ru/account/exhibition",
    component: _1af25fdb,
    name: "account-exhibition___ru"
  }, {
    path: "/ru/account/exhibition-plate",
    component: _31946424,
    name: "account-exhibition-plate___ru"
  }, {
    path: "/ru/account/payment",
    component: _9e0043a6,
    name: "account-payment___ru"
  }, {
    path: "/ru/account/schedule",
    component: _21cf65a3,
    name: "account-schedule___ru"
  }, {
    path: "/ru/login/change-password",
    component: _5843ea10,
    name: "login-change-password___ru"
  }, {
    path: "/ru/login/impersonalize",
    component: _6524bf60,
    name: "login-impersonalize___ru"
  }, {
    path: "/ru/login/recover",
    component: _59fd2a44,
    name: "login-recover___ru"
  }, {
    path: "/ru/registration/email",
    component: _1f49eda0,
    name: "registration-email___ru"
  }, {
    path: "/en/account/payment/colleague",
    component: _9cf9cdec,
    name: "account-payment-colleague___en"
  }, {
    path: "/ru/account/payment/colleague",
    component: _9cf9cdec,
    name: "account-payment-colleague___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru___default"
  }, {
    path: "/en/account/chat/:id?",
    component: _7e98f38f,
    name: "account-chat-id___en"
  }, {
    path: "/en/program/session/:alias?",
    component: _7c265882,
    name: "program-session-alias___en"
  }, {
    path: "/ru/account/chat/:id?",
    component: _7e98f38f,
    name: "account-chat-id___ru"
  }, {
    path: "/ru/program/session/:alias?",
    component: _7c265882,
    name: "program-session-alias___ru"
  }, {
    path: "/account/chat/:id?",
    component: _7e98f38f,
    name: "account-chat-id___ru___default"
  }, {
    path: "/en/episode/:sessionAlias?",
    component: _2f0d4baa,
    name: "episode-sessionAlias___en"
  }, {
    path: "/en/exhibitors/:id",
    component: _18d511cc,
    name: "exhibitors-id___en"
  }, {
    path: "/en/highlights/:alias",
    component: _96d557b2,
    name: "highlights-alias___en"
  }, {
    path: "/en/partners/:alias",
    component: _244009bb,
    name: "partners-alias___en"
  }, {
    path: "/en/press-center/:alias?",
    component: _8fc70472,
    name: "press-center-alias___en"
  }, {
    path: "/en/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___en"
  }, {
    path: "/program/session/:alias?",
    component: _7c265882,
    name: "program-session-alias___ru___default"
  }, {
    path: "/ru/episode/:sessionAlias?",
    component: _2f0d4baa,
    name: "episode-sessionAlias___ru"
  }, {
    path: "/ru/exhibitors/:id",
    component: _18d511cc,
    name: "exhibitors-id___ru"
  }, {
    path: "/ru/highlights/:alias",
    component: _96d557b2,
    name: "highlights-alias___ru"
  }, {
    path: "/ru/partners/:alias",
    component: _244009bb,
    name: "partners-alias___ru"
  }, {
    path: "/ru/press-center/:alias?",
    component: _8fc70472,
    name: "press-center-alias___ru"
  }, {
    path: "/ru/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___ru"
  }, {
    path: "/episode/:sessionAlias?",
    component: _2f0d4baa,
    name: "episode-sessionAlias___ru___default"
  }, {
    path: "/exhibitors/:id",
    component: _18d511cc,
    name: "exhibitors-id___ru___default"
  }, {
    path: "/highlights/:alias",
    component: _96d557b2,
    name: "highlights-alias___ru___default"
  }, {
    path: "/partners/:alias",
    component: _244009bb,
    name: "partners-alias___ru___default"
  }, {
    path: "/press-center/:alias?",
    component: _8fc70472,
    name: "press-center-alias___ru___default"
  }, {
    path: "/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
