import { storageKeys, getLs } from "@/utils/storage"

export default ({ store, $notifications }) => {
  // См. @/mixins/back
  store.commit("app/SET_HISTORY_LEN",
    window?.history?.length ?? 0, { root: true })

  // Имперсонализация
  store.commit("user/SET_PREVIOUS_TOKEN",
    getLs(storageKeys.previousToken), { root: true })

  // Уведомления ЛС
  $notifications.restart()
}
