// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D6mHpBxZ{background:#000;color:#fff}.qUMTE6Rm{min-height:100vh}.H5wJQfGc{font-size:4.5rem;line-height:1}.MmeOrWLx{font-size:1.5rem;margin-bottom:.5em}.aFqQ4n1e{font-size:1.13rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": "D6mHpBxZ",
	"container": "qUMTE6Rm",
	"heading": "H5wJQfGc",
	"msg": "MmeOrWLx",
	"link": "aFqQ4n1e"
};
module.exports = ___CSS_LOADER_EXPORT___;
