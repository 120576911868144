import { storageKeys, setLs, removeLs } from "@/utils/storage"

export const state = () => ({
  statuses: [],
  favoriteSessions: [],
  favoriteUsers: [],
  favoriteExhibitors: [],
  previousToken: "",
  exhibition: null,
})

export const getters = {
  user: (_state, _getters, rootState) => rootState.auth.user,

  isOffline: (_state, getters) =>
    getters.user?.participationType === "offline",

  isAdmin (_state, getters) {
    const { user } = getters
    if (!user) return false
    return ["admin", "superadmin", "adminStatistics", "adminProgram"]
      .some(r => user.roles.includes(r))
  },

  isApprovableStatus (_state, getters) {
    const { user } = getters
    if (!user) return false
    return [
      "speaker",
      "mentor",
      "mentor-speaker",
      "organizer",
      "media",
      "student",
    ].includes(user.status.alias)
  },

  isPayableStatus (_state, getters) {
    const { user } = getters
    if (!user) return false
    return [
      "skresident",
      "corporation",
      "investor",
      "student",
      "science",
      "authorities",
      "infrastructure",
      "innovator",
    ].includes(user.status.alias)
  },

  isPaymentAllowed (_state, getters) {
    const { isApprovableStatus, isPayableStatus } = getters
    if (isApprovableStatus) return getters.isApproved && isPayableStatus
    return isPayableStatus
  },

  isPaid (_state, getters) {
    const { user } = getters
    if (!user) return false
    return user.states.includes("paid")
  },

  isApproved (_state, getters) {
    const { user } = getters
    if (!user) return false
    return user.states.includes("approved")
  },

  canMeet (_state, getters) {
    const { user } = getters
    if (!user) return false
    return getters.isOffline && !user.states.includes("wait_approval")
  },

  isCarPassAllowed (_state, getters) {
    return getters.isAdmin
    // if (!getters.isAdmin) return false

    // const { user } = getters
    // if (!user) return false
    // // return user.attendant
    // return ([
    //   "speaker",
    //   // "mentor",
    //   // "mentor-speaker",
    // ].includes(user.status.alias) && getters.isApproved)
  },

  isBadgeVisible (_state, _getters) {
    return false
    // return getters.isOffline && (getters.isPaid || getters.isApproved)
  },

  isEditAllowed (_state, getters) {
    const { user } = getters
    if (!user) return false
    if (getters.isBadgeVisible) return false
    return !(user.attributes.passportNumber && getters.isPaid)
  },

  isExhibitionEditAllowed (state) {
    return state.exhibition?.type === "online"
  },
  isExhibitionAllowed (_state, _getters) {
    return false

    // const { user } = getters
    // if (!user) return false
    // return user.email === "1728239+3@gmail.com"

    // return getters.isAdmin
    // return getters.user?.status.alias === "exhibitor"
    // return getters.user?.attributes?.wantToExhibit || false
  },

  isContestVisible (_state, getters) {
    const { user } = getters
    if (!user) return false
    const status = user.status.alias
    if ([
      "organizer",
      "speaker",
      "mentor",
      "media",
      "exhibitor",
      "mentor-speaker",
    ].includes(status)) return false
    return status === "contestant" || user.attributes.wantToCompete
  },

  statuses: state => state.statuses || [],

  getStatusesOptions: (_state, getters) => (localize) => {
    const entries = getters.statuses
      .map(s => [s.alias, localize(s.title)])
    return Object.fromEntries(entries)
  },

  favoriteSessions: state => state.favoriteSessions || [],

  isSessionFavorite: (_state, getters) => session =>
    Boolean(getters.favoriteSessions.find(s => s.alias === session.alias)),

  favoriteUsers: state => state.favoriteUsers || [],

  favoriteExhibitors: state => state.favoriteExhibitors || [],

  isExhibitorFavorite: (_state, getters) => exhibitor =>
    Boolean(getters.favoriteExhibitors.find(e => e.id === exhibitor.id)),
  isUserFavorite: (_state, getters) => user =>
    Boolean(getters.favoriteUsers.find(u => u.id === user.id)),

  isImpersonalized: state => Boolean(state.previousToken),
}

export const mutations = {
  SET_STATUSES (state, statuses) { state.statuses = statuses },
  SET_FAVORITE_SESSIONS (state, favoriteSessions) {
    state.favoriteSessions = favoriteSessions
  },
  SET_FAVORITE_USERS (state, favoriteUsers) {
    state.favoriteUsers = favoriteUsers
  },
  SET_FAVORITE_EXHIBITORS (state, favoriteExhibitors) {
    state.favoriteExhibitors = favoriteExhibitors
  },
  SET_PREVIOUS_TOKEN (state, token) {
    state.previousToken = token
  },
  SET_EXHIBITION (state, exhibition) {
    state.exhibition = exhibition
  },
}

export const actions = {
  async updateUser (_, payload) {
    const [res, err] = await this.$api.user.updateUser(payload)
    if (err) return [res, err]
    try {
      await this.$auth.fetchUser()
      return [null, null]
    } catch (e) { return [null, e] }
  },

  async fetchStatuses ({ commit }) {
    const [res, err] = await this.$api.event.getStatuses()
    if (err) {
      console.error(err)
      return
    }
    const statuses = this.$api.helpers.getList(res)
    commit("SET_STATUSES", statuses)
  },

  async fetchFavoriteSessions ({ commit }) {
    let favoriteSessions = []
    if (this.$auth.loggedIn) {
      const [res, err] = await this.$api.program.getFavoriteSessions()
      if (err) {
        console.error(err)
        return
      }
      favoriteSessions = res || []
    }
    commit("SET_FAVORITE_SESSIONS", favoriteSessions)
  },

  async setFavoriteSession ({ dispatch }, { alias, isFavorite }) {
    const method = isFavorite ? "addFavoriteSession" : "removeFavoriteSession"
    const [, err] = await this.$api.program[method](alias)
    if (err) {
      console.error(err)
      return
    }
    await dispatch("fetchFavoriteSessions")
  },

  async fetchFavoriteUsers ({ commit }) {
    let favoriteUsers = []
    if (this.$auth.loggedIn) {
      const [res, err] = await this.$api.user.getFavoriteUsers()
      if (err) {
        console.error(err)
        return
      }
      favoriteUsers = this.$api.helpers.getList(res)
    }
    commit("SET_FAVORITE_USERS", favoriteUsers)
  },

  async setFavoriteUser ({ dispatch }, { id, isFavorite }) {
    const method = isFavorite ? "addFavoriteUser" : "removeFavoriteUser"
    const [, err] = await this.$api.user[method](id)
    if (err) {
      console.error(err)
      return
    }
    await dispatch("fetchFavoriteUsers")
  },

  async fetchFavoriteExhibitors ({ commit }) {
    let favoriteExhibitors = []
    if (this.$auth.loggedIn) {
      const [res, err] = await this.$api.exhibition.getFavoriteApps()
      if (err) {
        console.error(err)
        return
      }
      favoriteExhibitors = this.$api.helpers.getList(res)
    }
    commit("SET_FAVORITE_EXHIBITORS", favoriteExhibitors)
  },

  async setFavoriteExhibitor ({ dispatch }, { id, isFavorite }) {
    const method = isFavorite ? "addFavoriteApp" : "removeFavoriteApp"
    const [, err] = await this.$api.exhibition[method](id)
    if (err) {
      console.error(err)
      return
    }
    await dispatch("fetchFavoriteExhibitors")
  },

  async impersonalize ({ commit, getters }, token) {
    if (!process.browser) return
    if (!getters.isImpersonalized) {
      const previousToken = this.$auth.strategy.token.get()
      if (previousToken) {
        commit("SET_PREVIOUS_TOKEN", previousToken)
        setLs(storageKeys.previousToken, previousToken)
      }
    }
    await this.$auth.setUserToken(token)
    this.$notifications.restart()
  },

  async exitImpersonalization ({ getters, state, commit }) {
    if (!process.browser) return
    if (!getters.isImpersonalized) return
    await this.$auth.setUserToken(state.previousToken)
    this.$router.push(this.localePath("/account"))
    this.$notifications.restart()
    commit("SET_PREVIOUS_TOKEN", "")
    removeLs(storageKeys.previousToken)
  },

  async logout ({ dispatch }) {
    await dispatch("exitImpersonalization")
    await this.$auth.logout()
    this.$notifications.restart()
    removeLs(storageKeys.passportModalWasOpen)
    removeLs(storageKeys.programOnboardingWasOpen)
    await dispatch("fetchExhibition")
  },

  async fetchExhibition ({ commit }) {
    let exhibition = null
    if (this.$auth.loggedIn) {
      const [res, err] = await this.$api.exhibition.getMyApp()
      if (err) console.error(err)
      else exhibition = res || null
    }
    commit("SET_EXHIBITION", exhibition)
  },
}
