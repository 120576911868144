// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q1Y9-vbl{--rabbet-color:currentColor;background:none;color:#b1ec52;font-size:.81rem;font-weight:400;height:3em;line-height:1.4;text-transform:capitalize;transition:background .2s ease-in-out;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:3em}@media(hover:hover){.Q1Y9-vbl:hover{background:rgba(177,236,82,.1);color:#b1ec52}}@media(hover:none){.Q1Y9-vbl:active{background:rgba(177,236,82,.1);color:#b1ec52}}@media(max-width:767.98px){.Q1Y9-vbl{height:2.7em;width:2.7em}}.Q1Y9-vbl svg{height:1.4em;width:1.4em}.Q1Y9-vbl svg *{fill:currentColor}.INmyUAlL{color:#000}@media(hover:hover){.INmyUAlL:hover{background:rgba(0,0,0,.1);color:#000}}@media(hover:none){.INmyUAlL:active{background:rgba(0,0,0,.1);color:#000}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Q1Y9-vbl",
	"btnDark": "INmyUAlL"
};
module.exports = ___CSS_LOADER_EXPORT___;
