
import buttonMixin from "@/mixins/button"

export default {
  mixins: [buttonMixin],

  props: {
    isDark: { type: Boolean, default: false },
  },
}
