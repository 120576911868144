
import { Swiper } from "vue-awesome-swiper"
import { nanoid } from "nanoid"
import "swiper/css/swiper.min.css"

export default {
  components: { Swiper },

  props: {
    // https://swiperjs.com/swiper-api#parameters
    options: { type: Object, required: true },
    navTop: { type: Number, default: 50 },
    padding: { type: Number, default: 0 },
    noNav: { type: Boolean, default: false },
  },

  data () {
    const { $style } = this
    return {
      nav: this.noNav
        ? []
        : [{ class: $style.prevEl }, { class: $style.nextEl }]
          .map(i => ({ ...i, id: nanoid() })),
    }
  },

  computed: {
    swiperOptions () {
      const { nav } = this
      return {
        grabCursor: true,
        freeMode: true,
        navigation: this.noNav
          ? false
          : {
            prevEl: `#${nav[0].id}`,
            nextEl: `#${nav[1].id}`,
            disabledClass: this.$style.hidden,
          },
        ...this.options,
      }
    },
  },
}
