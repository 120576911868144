// https://vee-validate.logaretm.com/v3/overview.html#usage

import Vue from "vue"
import {
  // eslint-disable-next-line camelcase
  email, required, numeric, min, max, mimes, size, min_value, double, confirmed,
} from "vee-validate/dist/rules"
import {
  ValidationProvider, ValidationObserver, extend, localize, setInteractionMode,
} from "vee-validate"
import ru from "vee-validate/dist/locale/ru.json"
import en from "vee-validate/dist/locale/en.json"
import { patterns } from "@/utils/_"

function cleanFieldPlaceholders (locale) {
  const messages = Object.fromEntries(
    Object.entries(locale.messages)
      .map(m => [m[0], m[1].replace(" {_field_} ", " ")]),
  )
  return { ...locale, messages }
}

export default ({ app: { i18n } }) => {
  setInteractionMode("eager")

  localize({ ru: cleanFieldPlaceholders(ru), en: cleanFieldPlaceholders(en) })
  localize(i18n.locale)

  Vue.component("ValidationObserver", ValidationObserver)
  Vue.component("ValidationProvider", ValidationProvider)

  extend("required", required)
  extend("email", email)
  extend("numeric", numeric)
  extend("min", min)
  extend("max", max)
  extend("mimes", mimes)
  extend("size", size)
  extend("min_value", min_value)
  extend("double", double)
  extend("confirmed", confirmed)

  extend("notLatin", v =>
    new RegExp(patterns.notLatin).test(v) || i18n.t("errors.notLatin"))
  extend("notCyr", v =>
    new RegExp(patterns.notCyr).test(v) || i18n.t("errors.notCyr"))
  extend("latinNum", v =>
    new RegExp(patterns.latinNum).test(v) || i18n.t("errors.latinNum"))
  extend("latinNumCyr", v =>
    new RegExp(patterns.latinNumCyr).test(v) || i18n.t("errors.latinNumCyr"))

  extend("url", v =>
    /^https?:\/\/.+$/.test(v) || i18n.t("errors.url"))
}
