// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xDvZ\\+Xc{padding:max(4.22rem,min(6.25vw,7.5rem)) 0 max(3.59rem,min(5.31vw,6.38rem))}.vfSakZRd{color:#000}.\\-1lfzi4S>*+*{margin-left:max(1rem,min(2.92vw,3.5rem))}@media(max-width:1365.98px){.\\-1lfzi4S>*+*{margin-left:0;margin-top:max(1rem,min(3.75vw,4.5rem))}}.fERybc1U>*+*{margin-left:max(1rem,min(7.55vw,9.06rem))}@media(max-width:1365.98px){.fERybc1U>*+*{margin-left:0;margin-top:max(1rem,min(3.75vw,4.5rem))}}.jZcpxq55{background-color:transparent;border:0;color:inherit}@media(hover:hover){.jZcpxq55:hover{color:inherit}}@media(hover:none){.jZcpxq55:active{color:inherit}}.jZcpxq55>:first-child{height:auto;-o-object-fit:contain;object-fit:contain;width:max(4.57rem,min(6.77vw,8.13rem))}._0ebS0M5k{margin-bottom:max(1.34rem,min(1.98vw,2.38rem))}._0ebS0M5k>*+*{margin-left:.81rem}.vGum1xLI{border:1px solid;color:inherit;height:2.5rem;transition:background .2s ease-in-out;width:2.5rem}@media(hover:hover){.vGum1xLI:hover{background:rgba(177,236,82,.1);color:inherit}}@media(hover:none){.vGum1xLI:active{background:rgba(177,236,82,.1);color:inherit}}.enIdOJ2x{height:1rem;width:1rem}.enIdOJ2x *{fill:currentColor}._2f-uoP\\+r,.-uZOde80{font-size:.81rem;line-height:1.4}.-uZOde80{font-weight:600;gap:.5em 1em;margin-bottom:max(2.18rem,min(3.23vw,3.88rem))}.-uZOde80 a{color:inherit}@media(hover:hover){.-uZOde80 a:hover{color:inherit;-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.-uZOde80 a:active{color:inherit;-webkit-text-decoration:underline;text-decoration:underline}}.j9cWsXfO{gap:max(.98rem,min(1.46vw,1.75rem)) max(1.97rem,min(2.92vw,3.5rem))}.j9cWsXfO a{color:#52b9c6;font-size:.81rem;font-weight:600;line-height:1.4}@media(hover:hover){.j9cWsXfO a:hover{color:#52b9c6;-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.j9cWsXfO a:active{color:#52b9c6;-webkit-text-decoration:underline;text-decoration:underline}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3xDvZ+Xc",
	"containerDark": "vfSakZRd",
	"inner": "-1lfzi4S",
	"innerStart": "fERybc1U",
	"logo": "jZcpxq55",
	"socials": "_0ebS0M5k",
	"social": "vGum1xLI",
	"socialIcon": "enIdOJ2x",
	"copy": "_2f-uoP+r",
	"years": "-uZOde80",
	"contacts": "j9cWsXfO"
};
module.exports = ___CSS_LOADER_EXPORT___;
