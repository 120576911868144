
import Vue from "vue"
import type { Toast } from "@/plugins/toast.client"

export default Vue.extend({
  data: () => ({ toasts: [] as Toast[] }),

  mounted () { this.$nuxt.$on("toast", this.showToast) },
  beforeDestroy () { this.$nuxt.$off("toast") },

  methods: {
    showToast (toast: Toast) {
      this.toasts.push(toast)
      if (toast.autoHide ?? true) setTimeout(() => this.hideToast(toast), 4500)
    },

    hideToast (toast: Toast) {
      const idx = this.toasts.findIndex(t => t.id === toast.id)
      if (idx > -1) this.toasts.splice(idx, 1)
    },

    async onClick (toast: Toast) {
      const { onClick } = toast
      if (!onClick) return
      await onClick()
      this.hideToast(toast)
    },
  },
})
