import { wrap } from "../_"

type CreateAppData = any // TODO
type UpdateAppData = any // TODO

const pfx = "/exhibition"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/favorite`,
      { ...cfg })),

  addFavoriteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/application/${appId}/favorite`, {},
      { ...cfg })),

  removeFavoriteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/application/${appId}/favorite`,
      { ...cfg })),

  createApp: (data: CreateAppData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/application/my`, data,
      { ...cfg })),

  updateMyApp: (data: UpdateAppData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}/application/my`, data,
      { ...cfg })),

  getMyApp: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/my`,
      { ...cfg })),

  getApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application`,
      { cache: true, ...cfg })),

  getApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/${appId}`,
      { cache: true, ...cfg })),

  getSkData: (inn: number, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/sk/${inn}`,
      { cache: true, ...cfg })),
})
