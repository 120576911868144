import ReconnectingWebSocket from "reconnecting-websocket"
import { striptags } from "striptags"
import unescape from "lodash/unescape"

export function client (application, token = null) {
  return {
    onCloseCallbacks: [],
    onOpenCallbacks: [],
    onMessageCallbacks: [],
    socket: null,
    token,
    application,

    connect (chatUrl, data = {}) {
      this.socket = new ReconnectingWebSocket(chatUrl, "", {
        maxReconnectionDelay: 300000,
        reconnectionDelayGrowFactor: 1.6,
        startClosed: true,
      })

      this.socket.addEventListener("open", () => {
        this.onOpenCallbacks.forEach(callback => callback())
        this.send("connect", data)
      })

      this.socket.addEventListener("close", () => {
        this.onCloseCallbacks.forEach(callback => callback())
      })

      this.socket.addEventListener("message", () => {
        try {
          const message = JSON.parse(event.data)

          if (message.status === "error")
            // eslint-disable-next-line no-console
            console.error("Chat: ", message.data)

          if (typeof this.onMessageCallbacks[message.type] === "undefined")
            return

          this.onMessageCallbacks[message.type].forEach(callback => callback(message))
        } catch (e) {}
      })

      this.socket.reconnect()
    },
    send (type, data = {}) {
      this.socket.send(JSON.stringify({
        application: this.application,
        token: this.token ?? null,
        event: "main",
        type,
        data,
      }))
    },
    close () {
      this.socket.close()
    },
    addOnMessageListener (type, callback) {
      if (typeof this.onMessageCallbacks[type] === "undefined")
        this.onMessageCallbacks[type] = []

      this.onMessageCallbacks[type].push(callback)
    },
    addOnCloseListener (callback) {
      this.onCloseCallbacks.push(callback)
    },
    addOnOpenListener (callback) {
      this.onOpenCallbacks.push(callback)
    },
  }
}

export function renderText (raw, {
  checkName = false,
} = {}) {
  let text = raw
  if (checkName) {
    text = text.replace(/^@@@(.*?):(.*?)@@@/,
      "<div class=\"msg__quote\"><div class=\"msg__quote-name\">$1</div>$2</div>")
  } else {
    text = text.replace(/^@@@(.*?)@@@/,
      "<div class=\"msg__quote\">$1</div>")
  }
  return text
}

export function cleanReply (raw, {
  strip = false,
} = {}) {
  let text = raw
  text = text.replace(/^@@@.*?@@@/, "")
  if (strip) text = unescape(striptags(text))
  return text
}

export function wrapInReply (raw, {
  name = "",
} = {}) {
  let text = cleanReply(raw)
  text = striptags(text)
  if (name) text = `${name}:${text}`
  text = `@@@${text}@@@`
  return text
}

export function isStickerMessage (message) {
  return /^~~(.+)~~$/.test(message.text)
}

export function getStickerPath (sticker) {
  return sticker // require(`@/assets/img/stickers/${sticker.trim().replace(/~/g, "")}.png`)
}
