// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gItKLcNX{font-size:max(2rem,min(2.19vw,2.63rem));margin-bottom:1.33em}.DPeNn5BX{padding-top:3rem}@media(max-width:767.98px){.DPeNn5BX{padding-top:4rem}}.o\\+QBE4aX{max-width:576px}.o\\+QBE4aX form{display:flex;flex-direction:column;gap:1.5rem}.o\\+QBE4aX form button[type=submit]{margin-top:1rem;width:-webkit-max-content;width:-moz-max-content;width:max-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "gItKLcNX",
	"container": "DPeNn5BX",
	"inner": "o+QBE4aX"
};
module.exports = ___CSS_LOADER_EXPORT___;
