
export default {
  layout: "empty",
  // eslint-disable-next-line vue/require-prop-types
  props: ["error"],

  head () {
    return { title: this.$t("errors.error") }
  },

  computed: {
    msg () {
      return this.error.message || this.$t("errors.error")
    },
  },
}
