const initialState = () => ({
  currentChat: "",
  unreadCount: 0,
})

export const state = initialState

export const getters = {
  currentChat: state => state.currentChat ?? "",
  unreadCount: state => state.unreadCount ?? 0,
}

export const mutations = {
  SET_CURRENT_CHAT (state, currentChat) { state.currentChat = currentChat },
  SET_UNREAD_COUNT (state, unreadCount) { state.unreadCount = unreadCount },
  INCREMENT_UNREAD_COUNT (state) { state.unreadCount++ },
  DECREASE_UNREAD_COUNT (state, count) {
    state.unreadCount = Math.max(state.unreadCount - count, 0)
  },
  RESET (state) { Object.assign(state, initialState()) },
}
