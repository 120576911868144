import type { NuxtI18nInstance } from "@nuxtjs/i18n"

export const parseRes = (res: any) => {
  if (res?.config?.responseType === "blob") return res?.data ?? null
  return res?.data?.data ?? null
}

export function parseErr (i18n: NuxtI18nInstance) {
  return (err: any) => {
    const data = err?.response?.data
    return data?.message || data?.data?.error ||
      data?.data ||
      err ||
      i18n.t("errors.error")
  }
}

// Адаптируем вызовы API в стиле golang.
export const wrap = (res: Promise<any>) => res
  .then(r => [r, null]).catch(e => [null, e.response.data])

export const list = (res: Promise<any>) => res.then(d => d?.list || [])
