// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z-kdFPT1{list-style-type:none;max-width:calc(var(--max-w) + var(--px)*2);overflow-x:auto;overflow-y:hidden;padding-top:.15em}._47vxskER{border-bottom:1px solid rgba(197,205,231,.5)}._47vxskER .-uF29Xi-{border-bottom:0}.-uF29Xi-{background:rgba(197,205,231,.2);border-radius:max(.28rem,min(.42vw,.5rem)) max(.28rem,min(.42vw,.5rem)) 0 0;color:#1a153f;font-size:max(1rem,min(.94vw,1.13rem));font-weight:600;line-height:1.4;padding:max(.56rem,min(.83vw,1rem));transition:color,background .2s ease-in-out}@media(hover:hover){.-uF29Xi-:hover{background:rgba(125,143,201,.2);color:#1a153f}}@media(hover:none){.-uF29Xi-:active{background:rgba(125,143,201,.2);color:#1a153f}}.-uF29Xi-.nuxt-link-active{background:#b1ec52;color:#000}@media(hover:hover){.-uF29Xi-.nuxt-link-active:not(.nuxt-link-exact-active):hover{background:#80c315;color:#000}}@media(hover:none){.-uF29Xi-.nuxt-link-active:not(.nuxt-link-exact-active):active{background:#80c315;color:#000}}.-uF29Xi-.nuxt-link-exact-active{cursor:default}.LgZEj6yo{background:#d91413;color:#fff}.LgZEj6yo.nuxt-link-active{background:#d91413;color:#fff}@media(hover:hover){.LgZEj6yo.nuxt-link-active:hover{background:#d91413;color:#fff}}@media(hover:none){.LgZEj6yo.nuxt-link-active:active{background:#d91413;color:#fff}}@media(hover:hover){.LgZEj6yo:hover{background:#7b0b0b;color:#fff}}@media(hover:none){.LgZEj6yo:active{background:#7b0b0b;color:#fff}}.-uF29Xi-[disabled]{cursor:not-allowed;filter:grayscale(100%)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "z-kdFPT1",
	"containerWithBorder": "_47vxskER",
	"tab": "-uF29Xi-",
	"tabRed": "LgZEj6yo"
};
module.exports = ___CSS_LOADER_EXPORT___;
