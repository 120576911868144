import { wrap } from "../_"

type CreateAppData = any // TODO
type UpdateAppData = any // TODO

const pfx = "/contest"

export default (a: NuxtAxiosInstance) => ({
  createApp: (data: CreateAppData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/application/my`, data,
      { ...cfg })),

  updateMyApp: (data: UpdateAppData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}/application/my`, data,
      { ...cfg })),

  getMyApp: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/my`,
      { ...cfg })),

  getApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application`,
      { cache: true, ...cfg })),

  getApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/${appId}`,
      { cache: true, ...cfg })),
})
