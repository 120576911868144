// Хак. Используйте если $nextTick отрабатывает некорректно.
// Эта функция дожидается рендеринга.
export function doubleRaf (cb: Parameters<typeof requestAnimationFrame>[0]) {
  requestAnimationFrame(() => { requestAnimationFrame(cb) })
}

export const patterns = {
  notLatin: "^[^a-zA-Z]+$",
  notCyr: "^[^а-яА-ЯёЁ]+$",
  latinNum: "^[a-zA-Z0-9 -]+$",
  latinNumCyr: "^[a-zA-Z0-9а-яА-ЯёЁ -]+$",
}

export function scrollToTop (isSmooth = true) {
  window?.scrollTo?.({
    top: 0,
    left: 0,
    // @ts-ignore
    behavior: isSmooth ? "smooth" : "instant",
  })
}

export function getFailToken (
  error: NuxtContext["error"], i18n: NuxtContext["i18n"],
) {
  return () => error({
    statusCode: 404, message: i18n.t("registration.invalidToken") as string,
  })
}

export function getSessionDurationSec (session: any) {
  return (session.finish - session.start) / 1000
}

export function isSessionLive (session: any) {
  const now = new Date().getTime()
  return now >= session.start && now < session.finish
}

export const simpleUrl = (url: string) =>
  url.replace(/^https?:\/\//, "").replace(/\/$/, "")

export function onLoad (cb: Function, delay = 1) {
  const delayedCb = () => setTimeout(() => cb(), delay)
  if (document.readyState === "complete") delayedCb()
  else window.addEventListener("load", delayedCb, { once: true })
}
