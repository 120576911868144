
import Vue from "vue"

export default Vue.extend({
  props: {
    isDark: { type: Boolean, default: false },
  },

  data () {
    return {
      email: "openinnovations@sk.ru",
      year: this.$config.year,
      socials: {
        vk: "https://vk.com/forinnovations",
      },
    }
  },

  computed: {
    oldYears (): number[] {
      const from = 2017
      return Array.from({ length: this.year - from }, (_, i) => from + i)
    },
  },
})
