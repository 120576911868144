import set from "lodash/set"
// import cacheAdapterEnhancer from "@fengsi/nuxt-axios-cache/dist/cacheAdapterEnhancer"
import { parseRes, parseErr } from "./api/_"

const plugin: NuxtPlugin = ({ $axios, app, $sentry }) => {
  // // Включаем кэширование
  // let adapter
  // if (process.server) {
  // // adapter = cacheAdapterEnhancer($axios.defaults.adapter, {
  // //   enabledByDefault: false,
  // //   // @ts-ignore
  // //   defaultCache: ssrContext!.$axCache,
  // // })
  // } else {
  // adapter = cacheAdapterEnhancer($axios.defaults.adapter, {
  //     enabledByDefault: false,
  //     cacheBrowserEnable: true,
  // })
  // }
  // if (adapter) $axios.defaults.adapter = adapter

  $axios.onRequest((req) => {
    // Устанавливаем локаль
    if (!req.params) req.params = {}
    req.params.lang = app.i18n.locale
  })

  // Парсим ответ
  $axios.onResponse((res) => { res.data = parseRes(res) })

  $axios.onResponseError((e) => {
    let [req, res] = [{}, {}]
    try {
      req = JSON.parse(e.config.data)
      res = JSON.parse(JSON.stringify(e.response?.data || {}))
    } catch {}
    if (Object.keys(req).length || Object.keys(res).length) {
      $sentry.setContext("request", req)
      $sentry.setContext("response", res)
      $sentry.captureException(e)
    }

    // Унифицируем сообщение об ошибке
    set(e, "response.data", parseErr(app.i18n)(e))
  })
}
export default plugin

declare module "axios" {
  interface AxiosRequestConfig {
    cache?: boolean
  }
}
