import layoutMixin from "./layout"

export default {
  mixins: [layoutMixin],

  head () {
    return { title: this.title }
  },

  computed: {
    title () {
      const { titleToken } = this.layoutProps
      return titleToken ? this.$t(titleToken) : undefined
    },
    light () {
      const { isLight } = this.layoutProps
      return !isLight
    },
  },
}
