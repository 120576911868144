// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M9jldxFy{color:#000}.KVpN8KgN{--user-position-color:#1a153f!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": "M9jldxFy",
	"user": "KVpN8KgN"
};
module.exports = ___CSS_LOADER_EXPORT___;
