
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import type { Person } from "@/types/api/person"

export default Vue.extend({
  props: {
    user: { type: Object as VuePropType<Person>, required: true },
    actions: { type: Array as VuePropType<string[]>, default: () => [] },
    withFavorite: { type: Boolean, default: false },
    withInner: { type: Boolean, default: false },
    noHover: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters("user", {
      me: "user",
      iCanMeet: "canMeet",
    }),

    isMe (): boolean {
      const { user } = this
      const userId = this.withInner ? user.user?.id : user.id
      return this.me?.id === userId
    },

    img (): string {
      const { user } = this
      return user.frontPagePhoto?.url ||
        user.photo?.url ||
        user.user?.photo?.url ||
        ""
    },

    website (): string {
      const { user } = this
      return this.withInner
        ? user.user?.attributes?.website || ""
        : user.attributes?.website || ""
    },

    isFavorite (): boolean {
      const { user } = this
      return this.$store.getters["user/isUserFavorite"](this.withInner
        ? user.user || user
        : user)
    },

    isMeetingVisible (): boolean {
      const { user } = this
      const canMeet = this.withInner ? user.user?.canMeet : user.canMeet
      const isVisible = this.actions.includes("meeting") && canMeet
      if (isVisible && !this.$auth.loggedIn) return true
      return isVisible && !this.isMe && this.iCanMeet
    },

    isChatVisible (): boolean {
      let { user } = this
      user = this.withInner ? user.user || user : user
      const { visible } = user
      const show = this.actions.includes("chat") && Boolean(this.chatUrl) && visible
      if (show && !this.$auth.loggedIn) return true
      return show && !this.isMe
    },

    isWebsiteVisible (): boolean {
      return this.actions.includes("website") && Boolean(this.website)
    },

    isBioVisible (): boolean {
      return this.actions.includes("bio")
    },

    chatUrl (): string {
      const { user } = this
      const id = this.withInner ? user.user?.id : user.id
      if (!id) return ""
      return this.localePath(`/account/chat?user=${id}`)
    },

    hoverable (): boolean {
      return !this.noHover && this.actions.length > 0
    },
  },

  methods: {
    ...mapActions("user", ["setFavoriteUser"]),

    goToLogin () {
      this.$router.push(this.localePath("/login"))
    },

    toggleFavorite () {
      if (!this.$auth.loggedIn) return this.goToLogin()
      this.setFavoriteUser({ id: this.user.id, isFavorite: !this.isFavorite })
    },

    appointMeeting () {
      if (!this.$auth.loggedIn) return this.goToLogin()
      this.$emit("appoint-meeting")
    },
  },
})
